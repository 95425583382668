@use '@angular/material' as mat;
@import '~normalize.css';
@import './app/styles/components/components';
@import './app/styles/variables.scss';
@import "./app/styles/mixins";
@import './app/styles/components/notification';
@import './app/styles/components/buttons';
@import url("https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0-alpha.6/css/bootstrap.min.css");
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();


// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$rotazone-app-primary: mat.define-palette(mat.$indigo-palette);
$rotazone-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$rotazone-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$rotazone-app-theme: mat.define-light-theme($rotazone-app-primary, $rotazone-app-accent, $rotazone-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($rotazone-app-theme);

/* You can add global styles to this file, and also import other style files */
body {
  //background-color: black;
}

html, body {
  height: 100%;
  overflow-x: hidden;
  position: relative;
  background: $catskill-white-color;
}

* {
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  font-family: $main-font;
}

input {
  background-color: transparent !important;
  outline: none !important;
}

// modal
.mat-dialog-container {
  //border-radius: 0 !important;
  padding: 0 !important;
  overflow: inherit !important;
  width: auto !important;
  height: auto !important;
  border-radius: 10px !important;
  background-color: white;
}

.mat-menu-content, .mat-select-panel {
    background-color: white;
}

/* Styles for pop-ups */
.pop-up {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &-card {
    box-sizing: border-box;
    width: calc(100% - 30px);
    padding: 0 80px;
    box-shadow: 0 2px 35px rgba(0, 0, 0, 0.22);
    background-color: $default-color;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 767px) {
      padding: 0 40px;
    }

    @media screen and (min-width: 768px) {
      width: 533px;
    }

    .card-title {
      @media screen and (max-width: 767px) {
        margin: 0 !important;
      }
    }

    &-header {
      width: 100%;
      height: 60px;
      font-family: $main-font;
      font-size: 22px;
      font-weight: 400;
      letter-spacing: 0.26px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      position: relative;
    }

    &-logo-block {
      margin: 88px auto 54px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: 767px) {
        margin: 44px auto 27px;
      }
    }

    &-logo {
      width: 224px;
      height: 52px;
      background-image: url("assets/icons/logo.png");
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &-footer {
      margin-top: 50px;
      margin-bottom: 52px;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 767px) {
        margin-top: 10px;
        margin-bottom: 27px;
      }

      button {
        width: 100%;
        height: 46px;
      }

      &__double {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @media screen and (max-width: 767px) {
          flex-direction: column;
          align-items: center;
        }

        button {
          width: 47%;
        }
      }
    }
  }
}

//spinner
.loader-inline img {
  height: 70px;
  width: 106px;
}

html, body {
  height: 100%;
  background: $catskill-white-color;
}

body {
  margin: 0;
  font-family: $main-font;
}

.no-padding-right {
  padding-right: 0 !important;
}

ul {
  list-style-type: none;
}

button {
  outline: none !important;
  border: none;
  background-color: white;
}

.max-width-300 {
  max-width: 300px;
}

.padding-left-30 {
  padding-left: 30px;
}

.padding-right-30 {
  padding-left: 30px;
}

li {
  outline: none !important;
}

.max-width-300 {
  max-width: 300px !important;
}

.font-9 {
  font-size: 9px !important;
}


/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
