$main-color: #fa9b70 !important;
$main-color-rgb: rgb(250, 155, 112);
$main-font: Arial, sans-serif;


$dark-green-color: #2d4a30 !important;
$mint-color: #bde1e2 !important;
$catskill-white-color: #f6fbfb !important;
$icon-gray-color: #979797 !important;
$default-color: #FFFFFF !important;
$black-color: #000000 !important;
$black-color-rgb: rgb(0,0,0);
$mine-shaft-color: #353535 !important;
$pampas-color: #f6f3f0 !important;
$alabaster-color: #F8F8F8 !important;
$corduroy-color: #626464 !important;
$dusty-grey-color: #9a9a9a !important;
$grey-color: #888888 !important;
$porcelain-color: #E4E8EB !important;

$warning-color: #fa1511 !important;

$mute-color: rgba(0,0,0,0.4);
