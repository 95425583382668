@import '../variables';

// rewrited input component

.mat-form-field-flex {
  border-radius: 0 !important;
  border: 1px solid $default-color;

  vertical-align: text-bottom;
}

mat-form-field.mat-form-field.mat-form-field-appearance-outline > div.mat-form-field-wrapper > div.mat-form-field-flex > div.mat-form-field-infix {
  padding: 0.4em 0
}

//mat-form-field.mat-form-field.mat-form-field-appearance-outline > div.mat-form-field-wrapper > div.mat-form-field-flex > div.mat-form-field-infix > span.mat-form-field-label-wrapper { top:  -0.75em; }
mat-form-field.mat-form-field.mat-form-field-appearance-outline > div.mat-form-field-wrapper > div.mat-form-field-flex > div.mat-form-field-infix > span.mat-form-field-label-wrapper {
  top: -1.1em;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  transform: translateY(-1.1em) scale(.75) !important;
  width: 133.33333%;
}

.mat-form-field-infix {
  border-top: 5px solid transparent !important;
  border-bottom: 5px solid transparent;
}

.mat-form-field-flex {
  padding: 0 12px;
}

.mat-input-element {
  box-sizing: border-box;
  padding: 0 5px !important;
}

.mat-form-field-label {

  //margin-top: 0 !important;
  span {
    background-color: red;
    color: white;
    padding: 0 5px;
  }
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  display: none;
}

.mat-focused {
  .mat-form-field-flex {
    border-radius: 0 !important;;
  }

  .mat-form-field-label {
    span {
      color: $main-color;
    }
  }
}

.mat-form-field-invalid {
  .mat-form-field-flex {
    //border: 2px solid $warning-color;
  }

  .mat-form-field-label {
    span {
      color: $warning-color;
    }
  }
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  span {
    color: #29829c !important;
  }
}

.mat-select-panel .mat-optgroup-label, .mat-select-panel .mat-option {
  span {
    color: rgba(0, 0, 0, 0.87);
    font-family: $main-font;
    font-size: 15px;
  }
}

//  buttons

.mat-button-base {
  //text-transform: uppercase;
}

.mat-button, .mat-flat-button, .mat-icon-button, .mat-stroked-button {
  height: 43px;
  border: 1px solid $main-color;
  color: #ffffff;
  font-family: $main-font;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.94px;
  border-radius: 0 !important;

  &:focus {
    outline: none !important;
  }
}

.mat-flat-button.mat-primary {
  background-color: $main-color;

  &:not([disabled]):hover {
    background-color: $main-color;
  }
}

.mat-flat-button.mat-primary[disabled] {
  color: $default-color;
  opacity: 0.5;
}

.mat-stroked-button.mat-primary {
  color: $main-color;
  border: 2px solid $main-color;
}

.mat-checkbox-disabled .mat-checkbox-label {
  color: #ffffff66 !important;
}

// dialog

.mat-dialog-actions {
  margin-bottom: 0 !important;
}

.mat-dialog-title {
  font-family: $main-font;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-transform: uppercase;
}

.mat-drawer {
  background-color: $catskill-white-color;
}

.mat-drawer-content {
  background-color: $catskill-white-color;
}

/* custom radio button */
.checkbox-container {
  input[type="checkbox"] {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    background: $dark-green-color;
    width: 18px;
    height: 18px;
    border-radius: 3px;
    margin-right: 8px;

    &:checked {
      background: $main-color;
      min-width: 18px;
      min-height: 18px;
    }

    &:after {
      content: "";
      position: relative;
      left: 37%;
      top: 19%;
      width: 28%;
      height: 50%;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      display: none;
    }

    &:checked:after {
      display: block;
    }
  }
}

//mat menu
.mat-menu-content {
  padding: 0 !important;
}

.mat-menu-panel.mat-menu-language {
  padding: 0 !important;
  min-width: unset;
  position: relative;
  top: 6px;

  .mat-menu-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-around;

    button {
      height: 40px;
      line-height: 40px;

      .mat-icon {
        margin-right: 10px;
        color: $main-color;
      }
    }
  }
}
