@import "../variables";

.apply-button {
  background: $main-color;
  color: #fff;
  padding: 15px 40px;
  outline: none;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 700;
  margin-top: 20px;
}

.cancel-button {
  height: 50px;
  border: 1px solid $main-color;
  color: $main-color;
  background-color: #ffffff;
  padding: 15px 40px;
  outline: none;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 700;
  margin-top: 20px;
  margin-right: 12px;
}
