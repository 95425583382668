.notification {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;

  &-item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgb(151, 151, 151, 0.2);
    padding: 20px 20px 12px 10px;

    &-badge {
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 10px;

      &.active {
        background-color: #2d4a30;
      }

      &.warn {
        background-color: #ff3d08;
      }

      &.inactive {
        background-color: #e5e5e5;
      }
    }

    &-title {
      font-weight: 700;
      font-size: 12px;
    }

    &-desc {
      font-size: 12px;
    }
  }

  .show-all {
    color: #fa9b70;
    font-size: 14px;
    padding: 20px 0;
    align-self: center;
    cursor: pointer;
    outline: none;
  }
}

.hide {
  display: none !important;
}
